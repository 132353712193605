import { FormikHelpers, useFormik } from "formik";
import images from "../../../constants/images";
import classes from "../login/LoginBox.module.scss";
import { useRef } from "react";
import Grid from "@mui/material/Grid/Grid";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button/Button";
import * as Yup from 'yup';

interface IOTPValues {
    otp1: string;
    otp2: string;
    otp3: string;
    otp4: string;
}

export const Otp = () => {

    let themeValue = "light";
    const storeTheme = localStorage.getItem("theme");
    if (JSON.parse(storeTheme as string) === "light" || (storeTheme as string) == null) {
        themeValue = "light";
    } else {
        themeValue = "dark";
    }

    const formik = useFormik<IOTPValues>({
        initialValues: {
            otp1: '',
            otp2: '',
            otp3: '',
            otp4: ''
        },
        validationSchema: Yup.object({
            otp1: Yup.string().required('Required'),
            otp2: Yup.string().required('Required'),
            otp3: Yup.string().required('Required'),
            otp4: Yup.string().required('Required'),
        }),
        onSubmit: (values: IOTPValues, formikHelpers: FormikHelpers<IOTPValues>) => {
            alert('OTP Submitted: ' + Object.values(values).join(''));
            formikHelpers.resetForm();
        }
    });

    const inputRefs = {
        otp1: useRef<HTMLInputElement | null>(null),
        otp2: useRef<HTMLInputElement | null>(null),
        otp3: useRef<HTMLInputElement | null>(null),
        otp4: useRef<HTMLInputElement | null>(null),
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, nextInputRef: React.RefObject<HTMLInputElement> | null) => {
        const { name, value } = e.target;
        if (value.length === 1 && nextInputRef && nextInputRef.current) {
            nextInputRef.current.focus();
        }
        formik.setFieldValue(name as keyof IOTPValues, value);
    };
   
    return (
        <div className={`${classes.container}`}>
            <div className={classes.leftBlur}></div>
            <div className={classes.logoWrapper}>
                {
                    themeValue === "dark" ? <img className={classes.logo} src={images.logo} alt="jontech" /> : <img className={classes.logo} src={images.logoBlack} alt="jontech" />
                }
            </div>
            <div className={classes.innerWrapper}>
                <div className={classes.wrapper}>
                    <div className={classes.loginBoxWrapper}>
                        <img src={images.rightBottom} className={classes.loginRightBg} />
                        <img src={images.leftTop} className={classes.loginRingLeft} />
                        <img src={images.rightTop} className={classes.rightTop} />
                        <div className={classes.loginBox}>
                            <div className={classes.otpTopContainer}>
                                <img src={images.OtpVerificationn} alt="otp-verification" />
                                <div className={classes.content}>{"Otp Verification"}</div>
                                <div className={classes.textContent}>{"We have sent you 4 digit code to your email or phone"}</div>
                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={2} justifyContent="center">
                                    {['otp1', 'otp2', 'otp3', 'otp4'].map((fieldName, index) => (
                                        <Grid item key={fieldName}>
                                            <TextField
                                                name={fieldName}
                                                value={formik.values[fieldName as keyof IOTPValues]}
                                                onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>, inputRefs[`otp${index + 2}` as keyof typeof inputRefs])}
                                                // onBlur={formik.handleBlur}
                                                inputRef={inputRefs[fieldName as keyof typeof inputRefs]}
                                                variant="outlined"
                                                inputProps={{ maxLength: 1, type: 'number' }}
                                                // error={formik.touched[fieldName as keyof IOTPValues] && Boolean(formik.errors[fieldName as keyof IOTPValues])}
                                                // helperText={formik.touched[fieldName as keyof IOTPValues] && formik.errors[fieldName as keyof IOTPValues]}
                                                className={classes.otpInput}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                <div className={classes.resendOtp}>
                                    {"Didn’t received the code? "}
                                    <span role="button">Resend code</span>
                                </div>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={!(formik.isValid && formik.dirty)}
                                    style={{ marginTop: '20px', color: "#fff", marginBottom: "20px" }}
                                >
                                    Submit
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.rightBlur} ></div>
            <div className={classes.loginRightImage}>
                <img src={images.loginRightImage} className={classes.loginRightImage} />
            </div>
        </div >
    )
};
