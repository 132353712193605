import ThemeBox from "./themeBox/ThemeBox";
import ProfileMenu from "./profile/ProfileMenu";
import WalletMenu from "./wallet/WalletMenu";
import classes from "./TopNavRightBox.module.scss";
import FullScreen from "./fullScreen/FullScreen";
import { useDevice } from "../../../../store/deviceContext";

function TopNavRightBox() {
  const { isDesktop, isMobile, isIPad, isLandscape} = useDevice();
  return (
    <div className={classes.topNavBox_right}>
      <div className={classes.wrapper}>
        <WalletMenu />
      </div>
      {(isDesktop || isIPad || (isMobile && isLandscape))&&
        <>
          <ThemeBox />
          <FullScreen />
        </>
      }
      <ProfileMenu />
    </div>
  );
}
export default TopNavRightBox;