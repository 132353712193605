
import { removeLocalStorageData, getApiUrlEndpointAsPerRole } from '../utils/utils';
import postService from '../services/post';
import getService from '../services/getService';
import { useContext } from 'react';
import LoginContext from '../store/loginContext';
import BalanceContext from '../store/balanceContext';
import { useNavigate } from 'react-router-dom';

const useLogout = () => {
  const loginCtx = useContext(LoginContext);
  const balanceCtx = useContext(BalanceContext);
  const navigate = useNavigate();

  const logOutServiceCall = async () => {
    try {
      const apiEndpoint = getApiUrlEndpointAsPerRole();
      const url = apiEndpoint.logoutApiURL;
      let result: any;
      if (process.env.REACT_APP_ADMIN_APP_NAME === 'owner') {
        result = await getService(url, {});
      } else {
        result = await postService(url, {});
      }
      return result;
    } catch (error) {
      throw error;
    } finally {
      navigate("/login");
      loginCtx.toggleLogin();
      removeLocalStorageData();
      balanceCtx?.clearBalanceInterval();
    }
  };
  return { logOutServiceCall };
};

export default useLogout;