import React, { ReactNode, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { ILoginData } from "../interfaces/interfaceCommon";

type TContext = {
  isLogin: boolean;
  toggleLogin: () => void;
  setDataOnLogin: (data: ILoginData) => void;
  loginData: ILoginData | null;
};

const LoginContext = React.createContext<TContext>({
  isLogin: false,
  toggleLogin: () => {},
  setDataOnLogin: () => {},
  loginData: null
});

export const LoginContextProvider: React.FC<{ children: ReactNode }> = (props) => {
  const [isLogin, setIsLogin] = useLocalStorage("isLogin", false);
  const [loginData, setLoginData] = useState<ILoginData | null>(null);

  function toggleLogin() {
    setIsLogin((prev) => !prev);
  }

  function setDataOnLogin(data:ILoginData) {
    setLoginData(data);
  }

  const loginValue: TContext = {
    isLogin: isLogin,
    toggleLogin,
    setDataOnLogin,
    loginData: loginData,
  };

  return (
    <LoginContext.Provider value={loginValue}>
      {props.children}
    </LoginContext.Provider>
  );
};

export default LoginContext;
