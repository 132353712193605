import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { FormError } from "../../FormError/FormError";
import { shouldShowError } from "../../../../utils/utils";

import { IInputBoxWithIconProps } from "../../../../interfaces/inputBoxWithIcon";

export const InputBoxWithIcon: React.FunctionComponent<IInputBoxWithIconProps> = ({ onChangeHandler, onBlurHandler, value, label, variant, name, id, errorMessage, isTouched, shouldAddMarginBottom, endAdornment, type, shouldWrapIcon }) => {
    return (
        <div>
            <FormControl fullWidth variant={variant} style={{ marginBottom: `${shouldAddMarginBottom ? "24px" : "0"}` }}>
                <InputLabel htmlFor={id}>{label}</InputLabel>
                <OutlinedInput
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                    value={value}
                    name={name}
                    fullWidth
                    id={id}
                    label={label}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px", // Custom border-radius for the input field
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "10px", // Custom border-radius for the outline
                        },
                    }}
                    endAdornment={!!endAdornment ? endAdornment() : null}
                    type={type}
                    autoComplete="off"
                    style={{ paddingRight: `${shouldWrapIcon ? "0" : "14px"}`, overflow: `${shouldWrapIcon ? "hidden" : "auto"}` }}
                />
            </FormControl>
            {shouldShowError(errorMessage, isTouched) && <div>
                <FormError error={errorMessage} />
            </div>}
        </div>
    )
}