import { useContext } from "react";
import { Icon } from "@iconify/react";
import { useWindowSize } from "usehooks-ts";
import TopNavRightBox from "./rightBox/TopNavRightBox";
import SidebarContext from "../../../store/sidebarContext";
import classes from "./TopNav.module.scss";
import { useLocation } from "react-router-dom";
import pageTitles from "./titles/pageTitle";
import { useDevice } from "../../../store/deviceContext";
import MarqueeHeader from "../marquee/Marquee";
import { images } from "../../../constants";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

// import ThemeBox from "./rightBox/themeBox/ThemeBox";
// import FullScreen from "./rightBox/fullScreen/FullScreen";
// import ProfileMenu from "./rightBox/profile/ProfileMenu";

function TopNav() {
  const sideOpenCtx = useContext(SidebarContext);
  const { width } = useWindowSize();
  const { isDesktop, isPortrait } = useDevice();
  function openSidebarHandler() {
    sideOpenCtx.toggleSidebar();
    if (width <= 768) document.body.classList.toggle("sidebar__open");
  }

  const location = useLocation();
  const currentPath = location.pathname;

  const currentTitle =
    pageTitles.find((item) => item.path === currentPath)?.title || "";

  return (
    // style={isDesktop ? {paddingBottom: "1rem"} : {paddingBottom: "0rem"}}
    <div className={classes.topNav}>
      <div className={classes.topNav_left}>
        <div
          className={classes.topNav_left_menu_icon} style={{marginTop: '7px'}}
          onClick={openSidebarHandler}
        >
          {/* <Icon icon="ci:menu-alt-03" width="24" /> */}
          <MenuRoundedIcon />
        </div>
        <div className={classes.topNav_left_menu} onClick={openSidebarHandler}>
          <div className="topNav_left_menu_open" style={{marginTop: '7px'}}>
            {/* <Icon icon="ci:menu-alt-03" width="24" /> */}
            <MenuRoundedIcon />
          </div>
          
          <div className="topNav_left_menu_close" style={{marginTop: '7px'}}>
            <Icon icon="eva:close-fill" width="24" />
          </div>
        </div>
        <div style={isPortrait ? { width: "60%" } : {}}>
          <div className={classes.pagTitle}>{currentTitle}</div>
        </div>
        {/* {(!isDesktop && isPortrait && !isIPad) && 
          <div className={classes.mobileIcons}>
            <ThemeBox />
            <FullScreen />
            <ProfileMenu />
          </div>
        } */}
      </div>
      {isDesktop && <MarqueeHeader />}
      <TopNavRightBox />
      <br />
    </div>
  );
}
export default TopNav;
