import { createContext, useState, useContext, ReactNode } from 'react';
import { getApiUrlEndpointAsPerRole } from '../utils/utils';
import getService from '../services/getService';
import { IQTProviderOptions } from '../interfaces/interfaceCommon';

interface FeatureProviderContextProps {
  qtProviderList: IQTProviderOptions[];
  gapProviderList: IQTProviderOptions[];
  fetchQTproviderList: () => void;
  fetchGapProviderList: () => void;
}

const FeatureProviderContext = createContext<FeatureProviderContextProps | undefined>(undefined);

export const FeatureProvider = ({ children }: { children: ReactNode }) => {
  const [qtProviderList, setQtProviderList] = useState<IQTProviderOptions[]>([]);
  const [gapProviderList, setGapProviderList] = useState<IQTProviderOptions[]>([]);

  const fetchQTproviderList = async () => {
    if(qtProviderList.length === 0) {
      try {
        const url = getApiUrlEndpointAsPerRole().qtProviderList;
        const result: any = await getService(url);
        if (result.qtProviderList) {
          setQtProviderList(result.qtProviderList);
        } else {
          console.error('API response data structure is unexpected:', result);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const fetchGapProviderList = async () => {
    if(gapProviderList.length === 0) {
      try {
        const url = getApiUrlEndpointAsPerRole().gapProviderList;
        const result: any = await getService(url);
        if (result.gapProviderList) {
          const transformedArray = result.gapProviderList.map((item:any, index:number) => ({
            id: item.providerName,
            name: item.providerName
          }));
          setGapProviderList(transformedArray);
        } else {
          console.error('API response data structure is unexpected:', result);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  return (
    <FeatureProviderContext.Provider value={{ qtProviderList, gapProviderList, fetchQTproviderList, fetchGapProviderList }}>
      {children}
    </FeatureProviderContext.Provider>
  );
};

export const useFeatureProvider = () => {
  const context = useContext(FeatureProviderContext);
  if (!context) {
    throw new Error('useFeatureProvider must be used within a FeatureProvider');
  }
  return context;
};

export default FeatureProviderContext;