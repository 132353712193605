import React, { createContext, useState, useEffect, useContext } from 'react';

interface DeviceContextProps {
  isDesktop: boolean;
  isMobile: boolean;
  isIOS: boolean;
  isIPad: boolean;
  isAndroid: boolean;
  isLandscape: boolean;
  isPortrait: boolean;
}

const DeviceContext = createContext<DeviceContextProps | undefined>(undefined);

export const DeviceProvider = ({ children }: { children: React.ReactNode }) => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isIPad, setIsIPad] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const [isPortrait, setIsPortrait] = useState(false);

  const checkDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    const isIOSDevice = /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream;
    const isAndroidDevice = /android/i.test(userAgent);
    const isMobileDevice = /Mobi|Android|iPhone|iPad/.test(userAgent);
    const ipadDevice = /iPad/.test(userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    const isDesktopDevice = !isMobileDevice;
    
    setIsIOS(isIOSDevice);
    setIsAndroid(isAndroidDevice);
    setIsIPad(ipadDevice);
    setIsMobile(isMobileDevice);
    setIsDesktop(isDesktopDevice);
  };

  const checkOrientation = () => {
    const isLandscapeMode = window.matchMedia("(orientation: landscape)").matches;
    setIsLandscape(isLandscapeMode);
    setIsPortrait(!isLandscapeMode);
  };

  useEffect(() => {
    checkDevice();
    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    return () => {
      window.removeEventListener('resize', checkOrientation);
    };
  }, []);

  return (
    <DeviceContext.Provider value={{ isDesktop, isMobile, isIOS, isAndroid, isIPad, isLandscape, isPortrait }}>
      {children}
    </DeviceContext.Provider>
  );
};

export const useDevice = () => {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error('useDevice must be used within a DeviceProvider');
  }
  return context;
};
