import config from "../../../../constants/config";
import { OwnerModuleNames, WhitelabelModuleNames } from "../../../../utils/enums";

let routesConstatnts: any;
if (process.env.REACT_APP_ADMIN_APP_NAME === 'owner') {
    routesConstatnts = config.roleBasedConfig['owner'].routesConstatnts;
} else {
    routesConstatnts = config.roleBasedConfig['whitelabel'].routesConstatnts;
}


const pageTitles = [
    { path: '/', title: OwnerModuleNames.DASHBOARD },
    { path: routesConstatnts.routeWhiteLabelList, title: OwnerModuleNames.WHITELABEL_LIST },
    { path: routesConstatnts.routeAddSubowner, title: OwnerModuleNames.ADD_SUBOWNER },
    { path: routesConstatnts.routeAdminRole, title: 'Admin Role Module' },
    { path: routesConstatnts.routeFeatureControl, title: OwnerModuleNames.FEATURE_CONTROL },
    { path: routesConstatnts.routeTransaction, title: OwnerModuleNames.ACCOUNT_STATEMENT },
    { path: routesConstatnts.paymentTransfer, title: OwnerModuleNames.PAYMENT_METHODS },
    { path: routesConstatnts.routeWhiteLabelUserList, title: WhitelabelModuleNames.SUBLEVEL_LISTING },
    { path: routesConstatnts.routePaymentMethod, title: WhitelabelModuleNames.PAYMENT_METHOD },
    { path: routesConstatnts.depositeWithdrawRoleManagement, title: WhitelabelModuleNames.PANEL_ROLE_MODULE },
    { path: routesConstatnts.depositeWithdrawPanel, title: WhitelabelModuleNames.PANEL_USER_LIST },
    { path: routesConstatnts.routeUserProfile, title: WhitelabelModuleNames.PROFILE },
    { path: routesConstatnts.routeGlobalSearch, title: WhitelabelModuleNames.GLOBAL_SEARCH },
    { path: routesConstatnts.routesSubUser, title: WhitelabelModuleNames.SUBUSER_LISTING },
    { path: routesConstatnts.routesSubUserRolePanel, title: WhitelabelModuleNames.SUBUSER_ROLE_PANEL },
];

export default pageTitles;