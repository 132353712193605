import { useContext } from "react";
import classes from "./Marquee.module.scss";
import SidebarContext from "../../../store/sidebarContext";
function MarqueeHeader() {
    const sidebarCtx = useContext(SidebarContext);
  return (
    <div className={`${classes.marqueeContainer} ${sidebarCtx.isOpen ? classes.adjusted : ''}`} >
      <h1 className={classes.marqueeText}>No new Notification!</h1>
    </div>
  );
}

export default MarqueeHeader;