import { TextField } from "@mui/material";
import { IInputBoxProps } from "../../../../interfaces/inputBox";
import { FormError } from "../../FormError/FormError";
import { shouldShowError } from "../../../../utils/utils";

export const InputBox: React.FunctionComponent<IInputBoxProps> = ({ onChangeHandler, onBlurHandler, value, label, variant, name, id, errorMessage, isTouched, startAdornment, type, disabled, maxRows, multiline }) => {
    return (
        <div>
            <TextField
                onChange={onChangeHandler}
                onBlur={onBlurHandler ? onBlurHandler : () => { }}
                value={value}
                name={name}
                fullWidth
                maxRows={maxRows}
                multiline={multiline}
                id={id}
                label={label}
                variant={variant}
                InputProps={{
                    startAdornment: startAdornment ? startAdornment : null,
                }}
                sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px", // Custom border-radius for the input field
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "10px", // Custom border-radius for the outline
                    },
                }}
                type={type || "text"}
                className="inputBox"
                disabled={disabled}
                autoComplete="off"
            />
            {errorMessage && <div>
                {shouldShowError(errorMessage, isTouched) && <FormError error={errorMessage} />}
            </div>}
        </div>
    )
}