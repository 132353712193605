import { Tooltip } from "@mui/material";
import { toggleFullScreen } from "../../../../../utils/utils";
import { images } from "../../../../../constants";

function FullScreen() {
  return (
    <Tooltip title="Full Screen">
      <div onClick={toggleFullScreen}>
        <div>
          <img src={images.fullScreen_icon} alt={'fullscreen'} style={{width: '1.4rem', height: '1.4rem'}} />		
          {/* <FitScreenIcon fontSize="large" style={{position: "relative",top: "1.5px", color:"var(--primaryColor)"}}/> */}
        </div>
      </div>
    </Tooltip>
  );
}

export default FullScreen;