import { IFormError } from "../../../interfaces/formError";
import classes from "./FormError.module.scss";
import React from "react"

export const FormError: React.FunctionComponent<IFormError> = React.memo(({ error }) => {
    return (
        <div className={classes.error}>
            {error}
        </div>
    )
})