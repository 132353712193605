import React, { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../components/common/sidebar/Sidebar";
import TopNav from "../components/common/topnav/TopNav";
import SidebarContext from "../store/sidebarContext";
import classes from "./MainLayout.module.scss";

const MainLayout = () => {
  const sidebarCtx = useContext(SidebarContext);
  // Don't show topbar & sideBar if its a new window having QueryParm available (yes in case of downline statement)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('id'); 
  useEffect(() => {
    if (document.body.classList.contains("sidebar__open"))
      document.body.classList.remove("sidebar__open");
  }, []);

  return (
    <div className={classes.container}>
      {!userId && <Sidebar />}
      <div className={classes.main}>
        <div className={`${classes.main__content} ${!sidebarCtx.isOpen ? classes.close_sidebar : ""} main_wrapper`} style={userId ? {padding: '1rem'}: {}}>
          {!userId && <TopNav />}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;