const images = {
  logo: require("../assets/images/project-logo.png"),
  logoMobile: require("../assets/images/project-logo-mobile.png"),
  avt: require("../assets/images/avatar.avif"),
  logKey: require("../assets/images/Reset password-pana.svg"),
  dashboard: require("../assets/images/Revenue-cuate.svg"),
  notFound: require("../assets/images/Oops 404 Error with a broken robot-cuate.svg"),

  dashboard_menu: require("../assets/images/dashboard.png"),
  payment_mathod: require("../assets/images/payment_mathod.png"),
  account_statement: require("../assets/images/account_statement.png"),
  WL_List: require("../assets/images/WL_List.png"),
  WL_admin_role: require("../assets/images/WL_admin_role.png"),
  add_subowner: require("../assets/images/add_subowner.png"),
  menu: require("../assets/images/menu.png"),
  plusSign: require("../assets/images/plusSign.png"),
  aadIcon: require("../assets/images/add.png"),
  theme_icon: require("../assets/images/theme.png"),
  logout_icon: require("../assets/images/logout.png"),
  fullScreen_icon: require("../assets/images/full-screen.png"),
  features_icon: require("../assets/images/features_control.png"),
  deposite_icon: require("../assets/images/deposite.png"),
  withdraw_icon: require("../assets/images/withdraw.png"),
  filter_icon: require("../assets/images/filterIcon.png"),
  rightBottom: require("../assets/images/1-coin.png"),
  leftTop: require("../assets/images/2-coin.png"),
  rightTop: require("../assets/images/3-coin.png"),
  loginRightImage: require("../assets/images/login-right-image.png"),
  OtpVerificationn: require("../assets/images/otp-verification.png"),
  logoBlack: require("../assets/images/logo-black.png"),
};

export default images;