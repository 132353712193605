import axiosInstance from '../interceptor/apiInterceptor';

const getService = async <T>(url: string, params?: any) => {
  try {
    const response = await axiosInstance.get<T>(url, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default getService;