import * as Yup from "yup";

export const changePasswordSchema = Yup.object({
    old_password: Yup.string().matches(/^(?=.*[A-Z])(?=.*\d).{8,}$/, 'Password must be at least 8 characters long, contain at least one uppercase letter and one number')
        .required("Old password required"),
    new_password: Yup.string().matches(/^(?=.*[A-Z])(?=.*\d).{8,}$/, 'Password must be at least 8 characters long, contain at least one uppercase letter and one number')
        .required("New password required"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('new_password'), undefined], 'Passwords must match')
        .required('Confirm Password is required'),
});