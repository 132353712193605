import React, { createContext, useContext, useState, ReactNode } from 'react';
import { MuiModal } from '../components/common/UI/muiModal/muiModal';

interface Modal {
  id: number;
  content: ReactNode;
  isOpen: boolean;
  title: string;
  modelWidth: string;
}

interface ModalContextType {
  modals: Modal[];
  openModal: (content: ReactNode, title?: string, modelWidth?: string) => void;
  closeModal: (id: number) => void;
  modalId: number;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [modals, setModals] = useState<Modal[]>([]);
  const [modalId, setModalId] = useState(0);

  const openModal = (content: ReactNode, title?: string, modelWidth?: string) => {
    const id = Date.now();
    const newModal: Modal = {
      id,
      content,
      isOpen: true,
      title: title ? title : "",
      modelWidth: modelWidth ? modelWidth : ""
    };
    setModals([...modals, newModal]);
    setModalId(id);
  };

  const closeModal = (id?: number) => {
    if (id === undefined) {
      const lastModal = modals[modals.length - 1];
      if (!!lastModal) {
        setModals(modals.map(modal => ({
          ...modal,
          isOpen: modal.id === lastModal.id ? false : modal.isOpen
        })));
      }
    } else {
      setModals(modals.map(modal => ({
        ...modal,
        isOpen: modal.id === id ? false : modal.isOpen
      })));
    }
  };

  const contextValue: ModalContextType = {
    modals,
    openModal,
    closeModal,
    modalId
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      {modals.map(modal => (
        modal.isOpen && (
          <MuiModal key={modal.id} title={modal.title} isOpen={modal.isOpen} modelWidth={modal.modelWidth} handleModalOpenClose={() => closeModal(modal.id)}>
            {modal.content}
          </MuiModal>
        )
      ))}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
