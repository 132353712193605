import ReactDOM from "react-dom/client";
import App from "./App";
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';
import { SidebarContextProvider } from "./store/sidebarContext";
import { LangContextProvider } from "./store/langContext";
import { ThemeContextProvider } from "./store/themeContext";
import { LoginContextProvider } from "./store/loginContext";
import { ModalProvider } from "./store/modalContext";
import { TimezoneProvider } from "./store/timezoneContext";
import { BalanceProvider } from "./store/balanceContext";
import { FeatureProvider } from "./store/featureProviderContext";
import { DeviceProvider } from "./store/deviceContext";
import { ModulePrivilageProvider } from "./store/modulePrivilageContext";

// Create a client
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root')!); // Create a root
root.render(
  <QueryClientProvider client={queryClient}>
    <DeviceProvider>
      <LangContextProvider>
        <TimezoneProvider>
          <LoginContextProvider>
            <ModulePrivilageProvider>
              <BalanceProvider>
                <FeatureProvider>
                  <ThemeContextProvider>
                    <SidebarContextProvider>
                      <ModalProvider>
                        <Toaster />
                        <App />
                      </ModalProvider>
                    </SidebarContextProvider>
                  </ThemeContextProvider>
                </FeatureProvider>
              </BalanceProvider>
            </ModulePrivilageProvider>
          </LoginContextProvider>
        </TimezoneProvider>
      </LangContextProvider>
    </DeviceProvider>
  </QueryClientProvider>,
);