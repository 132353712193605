import { Backdrop, IconButton, Modal } from "@mui/material"
import classes from "./muiModal.module.scss";
import CloseIcon from '@mui/icons-material/Close';
import { IMuiModalProps } from "../../../../interfaces/interfaceCommon";
import { useDevice } from "../../../../store/deviceContext";
import { useEffect, useState } from "react";


export const MuiModal: React.FunctionComponent<IMuiModalProps> = ({ children, isOpen, handleModalOpenClose, title, modelWidth }) => {
    const { isMobile, isPortrait, isLandscape } = useDevice();
    const startWidth = modelWidth ? modelWidth : '400px';
    const [width, setWidth] = useState<string>(startWidth);
    const [innerHeight, setInnerHeight] = useState<string>('');
    useEffect(() => {
        if(isMobile && isLandscape) {
            setInnerHeight(((75/100) * window.innerHeight)+'px');
        }
        if(isMobile && isPortrait) {
            setWidth('96%');  
        } else {
            setWidth(startWidth);
        }    
    }, [isPortrait, isLandscape]);
    
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isOpen}
            onClose={handleModalOpenClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}

        >
            <div className={classes.modalContentWrapper} style={(isMobile && isPortrait) ? {width:'96%'} : {width:width}}>
                {title && <div className={classes.modalTitle}>{title}</div>}
                <div className={classes.closeButtonIcon}>
                    <IconButton aria-label="close" onClick={handleModalOpenClose}>
                        <CloseIcon sx={{color:"var(--primaryColor)"}} />
                    </IconButton>
                </div>
                <div className={classes.innerModalWrapper} style={(isMobile && isLandscape) ? {maxHeight: innerHeight} : {maxHeight: '600px'}}>
                    {children}
                </div>
            </div>
        </Modal>
    )
}